import axios from 'axios'
import qs from 'qs'

const instance = axios.create({
  baseURL: '',
  paramsSerializer: function (params) {
    return qs.stringify(cleanObject(params))
  },
})

export const setBaseURL = url => {
  instance.defaults.baseURL = url
}

export const setAuthorization = token => {
  instance.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export default instance

const cleanObject = p => {
  const params = { ...p }
  for (const key of Object.keys(params)) {
    const value = String(params[key])
    if (value === 'null' || value === 'undefined' || value === '') {
      delete params[key]
    }
  }
  return params
}
