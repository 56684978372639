import { combineReducers } from 'redux'
import { FolderTypes } from '../../action_types'
import { arrayToObject, removeItem, insertItem, updateObjectInArray } from 'src/utils/helpers'

function all(state = {}, action) {
  switch (action.type) {
    case FolderTypes.GET_FOLDERS_SUCCESS:
    case FolderTypes.SEARCH_FOLDERS_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) }
    case FolderTypes.GET_FAVORITE_FOLDERS_SUCCESS:
      return { ...state, ...arrayToObject(action.data) }
    case FolderTypes.GET_FOLDER_SUCCESS:
    case FolderTypes.CREATE_FOLDER_SUCCESS:
    case FolderTypes.UPDATE_FOLDER_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      }
    case FolderTypes.ARCHIVE_FOLDER_SUCCESS:
    case FolderTypes.DELETE_FOLDER_SUCCESS:
      const { [action.id]: _, ...newState } = state
      return newState
    default:
      return state
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case FolderTypes.GET_FOLDERS_SUCCESS:
      return action.data.results
    case FolderTypes.CREATE_FOLDER_SUCCESS:
      return insertItem(state, action.data)
    case FolderTypes.UPDATE_FOLDER_SUCCESS:
      return updateObjectInArray(state, action.data)
      if (action.data.is_favourite) {
        return removeItem(state, action.data.id)
      } else {
        const has = state.some(s => s.id === action.data.id)
        if (has) {
          return updateObjectInArray(state, action.data)
        } else {
          return insertItem(state, action.data)
        }
      }
    case FolderTypes.ARCHIVE_FOLDER_SUCCESS:
    case FolderTypes.DELETE_FOLDER_SUCCESS:
      return removeItem(state, action.id)
    default:
      return state
  }
}

function favourite_ui(state = [], action) {
  switch (action.type) {
    case FolderTypes.GET_FAVORITE_FOLDERS_SUCCESS:
      return action.data
    case FolderTypes.UPDATE_FOLDER_SUCCESS:
      if (!action.data.is_favourite) {
        return removeItem(state, action.data.id)
      } else {
        const has = state.some(s => s.id === action.data.id)
        if (has) {
          return updateObjectInArray(state, action.data)
        } else {
          return insertItem(state, action.data)
        }
      }
    case FolderTypes.ARCHIVE_FOLDER_SUCCESS:
    case FolderTypes.DELETE_FOLDER_SUCCESS:
      return removeItem(state, action.id)
    default:
      return state
  }
}

function meta(state = {}, action) {
  switch (action.type) {
    case FolderTypes.GET_FOLDERS_SUCCESS:
      const { results, ...rest } = action.data
      return { ...state, ...rest }
    case FolderTypes.DELETE_FOLDER_SUCCESS:
    case FolderTypes.ARCHIVE_FOLDER_SUCCESS: {
      const { count } = state
      return { ...state, count: count - 1 }
    }
    default:
      return state
  }
}

function createdFolder(state = {}, action) {
  switch (action.type) {
    case FolderTypes.CREATE_FOLDER_SUCCESS:
      return action.data
    default:
      return state
  }
}

function searchedFolders(state = [], action) {
  switch (action.type) {
    case FolderTypes.SEARCH_FOLDERS_SUCCESS:
      return action.data.results
    default:
      return state
  }
}

function apiKeys(state = {}, action) {
  switch (action.type) {
    case FolderTypes.GET_FOLDER_API_KEYS_SUCCESS:
      return action.data
    case FolderTypes.CLEAR_API_KEY_DATA:
      return {}
    default:
      return state
  }
}

function webhooks(state = {}, action) {
  switch (action.type) {
    case FolderTypes.GET_FOLDER_WEBHOOKS_SUCCESS:
      return action.data
    case FolderTypes.CLEAR__WEBHOOK_DATA:
      return {}
    default:
      return state
  }
}

function currentFolder(state = {}, action) {
  switch (action.type) {
    case FolderTypes.GET_FOLDERS_SUCCESS: {
      const folders = action.data.results
      const hasFolder = folders.length > 0
      const oldFolder = folders.find(folder => folder.id === state.id)
      return oldFolder ? oldFolder : hasFolder ? { ...state, ...folders[0] } : {}
    }
    case FolderTypes.SET_CURRENT_FOLDER:
      // return { ...state, ...action.data };
      return action.data
    case FolderTypes.CREATE_FOLDER_SUCCESS:
      return action.data
    case FolderTypes.UPDATE_FOLDER_SUCCESS:
      return action.data.id === state.id ? { ...state, ...action.data } : state
    default:
      return state
  }
}

function documentTemplates(state = [], action) {
  switch (action.type) {
    case FolderTypes.GET_FOLDER_DOCUMENT_TEMPLATES_SUCCESS:
      return action.data
    case FolderTypes.CLEAR_FIELDS:
      return []
    default:
      return state
  }
}

function serviceLabels(state = {}, action) {
  switch (action.type) {
    case FolderTypes.GET_FOLDER_DOCUMENT_TEMPLATES_SUCCESS: {
      let newState = state
      action.data.map(dt => {
        dt.labels.map(l => {
          // if (!l.children.length === 0) {
          newState[l.id] = l
          // } else {
          l.children.map(c => {
            newState[c.id] = c
          })
          // }
        })
      })
      return newState
    }
    case FolderTypes.UPDATE_SERVICE_LABEL_SUCCESS: {
      return {
        ...state,
        [action.data.id]: action.data,
      }
    }
    default:
      return state
  }
}

function submissionFields(state = [], action) {
  switch (action.type) {
    case FolderTypes.GET_FOLDER_SUCCESS:
    case FolderTypes.SET_CURRENT_FOLDER: {
      return action.data.submission_fields ? action.data.submission_fields : []
    }
    case FolderTypes.CREATE_FOLDER_SUBMISSION_FIELD_SUCCESS: {
      if (action.data.parent) {
        let obj = state.filter(s => s.id === action.data.parent)[0]
        obj = {
          ...obj,
          children: [...obj.children, action.data],
        }
        return updateObjectInArray(state, obj)
      }
      return insertItem(state, action.data)
    }
    case FolderTypes.UPDATE_FOLDER_SUBMISSION_FIELD_SUCCESS: {
      if (action.data.parent) {
        return state
      }
      return updateObjectInArray(state, action.data)
    }
    case FolderTypes.CLEAR_FIELDS:
      return []
    case FolderTypes.DELETE_FOLDER_SUBMISSION_FIELD_SUCCESS:
      return removeItem(state, action.id)
    default:
      return state
  }
}

function fields(state = {}, action) {
  switch (action.type) {
    case FolderTypes.GET_FOLDER_SUCCESS:
    case FolderTypes.SET_CURRENT_FOLDER: {
      let newState = state
      if (action.data.submission_fields) {
        action.data.submission_fields.map(field => {
          newState[field.id] = field
          field.children.map(c => {
            newState[c.id] = c
          })
        })
      }
      return newState
    }
    case FolderTypes.CREATE_FOLDER_SUBMISSION_FIELD_SUCCESS:
    case FolderTypes.UPDATE_FOLDER_SUBMISSION_FIELD_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      }
    case FolderTypes.DELETE_FOLDER_SUBMISSION_FIELD_SUCCESS:
      const { [action.id]: _, ...newState } = state
      return newState
    default:
      return state
  }
}

export default combineReducers({
  all,
  ui,
  favourite_ui,
  meta,
  createdFolder,
  searchedFolders,
  apiKeys,
  webhooks,
  currentFolder,
  documentTemplates,
  serviceLabels,
  submissionFields,
  fields,
})
